<template>
  
  <v-container fluid>
    <v-row class="mt-3 mb-2" justify="center">
        <v-col
          cols="6"
          md="3"
        >
        <v-menu
          v-model="menuDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFormatted"
              readonly
              v-bind="attrs"
              v-on="on"

              dense
            outlined
            hide-details
            label="Період"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filterDate"
            type="month"
            locale="uk-UA"
            @input="menuDate = false"
          ></v-date-picker>
        </v-menu>
        </v-col>
        
        <v-col
         v-if="user && user.operator_id == 1"
          cols="6"
          md="3"
        >
        <v-select
          
          v-model="filterOperatorGroup"
          :items="operators_groups"
          label="Оператор"
          dense
          outlined
          hide-details
          item-text="name"
          item-value="id"
          @input="changeDistributorList"
          ></v-select>
        </v-col>
        <v-col
          cols="6"
          md="3"
        >
        <v-select
          v-model="filterDistributorGroup"
          :items="distributorList"
          label="Постачальник"
          clearable
          dense
          outlined
          hide-details
          item-text="name"
          item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="6" sm="2" lg="1">
          <v-row title="Організації" class="mx-0 px-0 d-flex" justify="center">
            <v-checkbox
              
              v-model="filterIsOrganization"
            ></v-checkbox>
            <v-icon :color="filterIsOrganization ? 'primary': ''">mdi-account-group</v-icon>
            
          </v-row>
        </v-col>
        <v-col
          cols="6"
          md="2"
          lg="1"
          class="text-center"
        >
          <v-btn color="primary" :loading="loading" @click="checkReportFinance()"
          >
            Отримати
          </v-btn>
        </v-col>
      </v-row>
    <v-row justify="center" class="">
      <v-col  md="12" lg="11">
        <v-data-table
          ref="tableTarifs"
          :headers="headers"
          loading-text="Завантаження..."
          no-data-text="Нема данних для відображення"
          :items-per-page="10"
          :page.sync="page"
          :items="finance"
          :server-items-length="paginations.count"
          :options.sync="options"
          :loading="loading"
          :footer-props="footer"
          :hide-default-footer="loading"
          class="elevation-1 pa-3"
          :class="$vuetify.theme.dark ? 'table-finance-dark-custom' : 'table-finance-light-custom'"
          focusable
          dense
        >
          <template v-slot:top>
            <v-toolbar dense flat :color="$vuetify.theme.dark ? 'background2' : 'white'">
              <v-toolbar-title><h2 class="mr-6">Фінансовий звіт</h2></v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          
            
          <template v-slot:body.prepend>
              <tr v-if="finance && finance.length > 0 && !$vuetify.breakpoint.mobile">
                <td colspan="3" class="custom-table">
                  <span></span>
                </td>
                <td colspan="2" class="custom-table text-center text-color-custom" >
                  <span>Залишок на початок періоду</span>
                </td>
                
                <td colspan="3" class="custom-table text-center text-color-custom">
                  <span>Нараховано за послуги</span>
                </td>
                <td colspan="1" class="custom-table text-center text-color-custom">
                </td>
                <td colspan="2" class=" text-center text-color-custom">
                  <span>Залишок на кінець періоду</span>
                </td>
              </tr>
          </template>

        <!-- ---------------------------------------- Cols ----------------------------------------- -->
          
        

          <template v-for="(el, index) in headers" v-slot:[`item.${el.value}`]="{value}">
            <span :key="index" :class="{'error--text': value < 0, 'grey--text': value == 0}">{{ value }}</span>
          </template>
          <template v-slot:item.name="{item, value}">
            <span >{{ value }}</span> <v-icon v-if="item.service_type && item.service_type != 1" style="vertical-align: sub;" color="error" class="ml-2">mdi-information-outline</v-icon>
          </template>
          <template #item.address="{ item }">
            <div href="#" v-if="true"  class="link_text" :class="$vuetify.theme.dark ? 'primary--text text--lighten-1' : ''" @click.prevent="openAbonentInfoModal(item)">{{ item && item.address }} <v-icon title="Видалений абонент" color="error" style="vertical-align: baseline;" small class="ml-1" v-if="item && item.is_removed">mdi-alert-circle</v-icon></div>
          </template>

          <template #item.index="{ item }" style="background: #eee">
            {{ finance.indexOf(item) + paginations.start_index }}
          </template>
        
        
        
        <!-- ---------------------------------------- /Cols ----------------------------------------- -->

        <template v-slot:body.append>
          <tr v-if="finance && finance.length > 0 && !$vuetify.breakpoint.mobile">
                <td colspan="11" class="">
                  
                </td>
          </tr>
              <tr v-if="finance && finance.length > 0 && !$vuetify.breakpoint.mobile">
                <td colspan="3" class="custom-table text-center text-color-custom">
                  <span>Всього: </span>
                </td>
                <td colspan="1" class=" text-end text-color-custom2" >
                  <span :class="{'error--text': total.debt_begin < 0, 'grey--text': total.debt_begin == 0}">{{ total.debt_begin }}</span>
                </td>
                <td colspan="1" class="custom-table text-end text-color-custom" >
                  <span :class="{'error--text': total.overpayment_begin < 0, 'grey--text': total.overpayment_begin == 0}">{{ total.overpayment_begin }}</span>
                </td>
                
                <td colspan="1" class=" text-end text-color-custom">
                  <span :class="{'error--text': total.monthly_fee < 0, 'grey--text': total.monthly_fee == 0}">{{ total.monthly_fee }}</span>
                </td>
                <td colspan="1" class=" text-end text-color-custom">
                  <span :class="{'error--text': total.services < 0, 'grey--text': total.services == 0}">{{ total.services }}</span>
                </td>

                <td colspan="1" class="custom-table text-end text-color-custom" >
                  <span :class="{'error--text': total.total < 0, 'grey--text': total.total == 0}">{{ total.total }}</span>
                </td>
                <td colspan="1" class="custom-table text-end text-color-custom">
                  <span :class="{'error--text': total.payments < 0, 'grey--text': total.payments == 0}">{{ total.payments }}</span>
                </td>
                <td colspan="1" class=" text-end text-color-custom">
                  <span :class="{'error--text': total.debt_end < 0, 'grey--text': total.debt_end == 0}">{{ total.debt_end }}</span>
                </td>
                <td colspan="1" class=" text-end text-color-custom">
                  <span :class="{'error--text': total.overpayment_end < 0, 'grey--text': total.overpayment_end == 0}">{{ total.overpayment_end }}</span>
                </td>
              </tr>
          </template>

          <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
            {{ pageStart }}-{{ pageStop }} із {{ itemsLength }}
          </template>

        </v-data-table>
      </v-col>
      </v-row>
      <keep-alive>
        <AbonentInfoModal ref="AbonentInfoModal" backTo="Фінансовий звіт" />
      </keep-alive>
  </v-container>
</template>

<script>
  import axios from "axios"
  import { mapGetters } from "vuex"
  import AbonentInfoModal from '@/components/Job/AbonentInfoModal.vue'
  export default {
    name: "report-tarifs-pays-inet",
    props: ["user"],
    components: {
      AbonentInfoModal
    },
    data: () => ({
      menuDate: false,
      updateTable: 0,
      finance: [],
      total: {},
      filterIsOrganization: true,
      filterOperatorGroup: null,
      filterDistributorGroup: null,
      filterDate: new Date().toISOString().substr(0, 7),
      distributorList: [],
      loading: false,
      options: {},
      page: 1,
      paginations: {},
      monthFormat: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
      yourColumnName: 'count',
      headers: [
        { text: '#', value: 'index', sortable: false,  width: 50},
        { text: "ПІБ", value: 'name', sortable: false, },
        { text: "Адреса", value: 'address', sortable: false, cellClass: 'custom-table  ',},
        { text: "Борг", value: 'debt_begin', sortable: false, cellClass: 'text-end fix-size-column ', class: 'text-end' },
        { text: "Переплата", value: 'overpayment_begin', sortable: false, cellClass: 'text-end custom-table fix-size-column ', class: 'text-end' },
        { text: "Абонплата", value: 'monthly_fee', sortable: false, cellClass: 'text-end fix-size-column ', class: 'text-end custom-table' },
        { text: "Додаткові послуги", value: 'services', sortable: false, cellClass: 'text-end fix-size-column ', class: 'text-end' },
        { text: "Всього", value: 'total', sortable: false, cellClass: 'text-end  custom-table fix-size-column ', class: 'text-end' },
        { text: "Отримано коштів", value: 'payments', sortable: false, cellClass: 'text-end  custom-table fix-size-column ', class: 'text-end' },
        { text: "Борг", value: 'debt_end', sortable: false, cellClass: 'text-end fix-size-column ', class: 'text-end' },
        { text: "Переплата", value: 'overpayment_end', sortable: false, cellClass: 'text-end fix-size-column ', class: 'text-end' },
      ],
      footer: {
          "items-per-page-options": [10,25,50],
          "items-per-page-text": " ", //"Абонентів на сторінці:"
          "items-per-page-all-text": "Всі",
          "show-first-last-page": true,
          "show-current-page": true,
      },
    }),
    computed: {
        ...mapGetters({
          operators_groups: 'filters/operators_groups',
          operators: "filters/operators",
        }),
        computedDateFormatted () {
          return this.formatDate(this.filterDate)
        },
    },
    mounted(){
      this.filterOperatorGroup = this.user.operator_id == 1 && 12 || this.user.operator_id
      this.changeDistributorList()
    },
    activated(){
      //
    },
    watch: {
      options: {
        handler (val, oldVal) {
          //this.getReportFinance();
          oldVal.page && this.getReportFinance();
        },
        deep: true,
      },
      updateTable(){
        this.updateTable && this.getReportFinance();
      },
    },
    methods: {
      formatDate (date) {
        if (!date) return null
        const [year, month] = date.split('-')
        return `${this.monthFormat[Number(month-1)]} ${year}`
      },
      async openAbonentInfoModal(abonent){
        this.$refs.AbonentInfoModal.paramsUrlId = abonent.user
        this.$refs.AbonentInfoModal.dialog = true
      },
      checkReportFinance(){
        if(this.options.page == 1){
          //console.log("checkGetAbonents")
          this.getReportFinance();
        }else{
          this.page = 1;
        }
      },
      getReportFinance(){
				this.loading = true;
				
        axios({
          method: "post",
          url: this.$router.app.devUrlBillingAPI,
          data: {req_cmd: "reportFinance", month: this.filterDate.split('-')[1], year: this.filterDate.split('-')[0], operator_group: this.filterOperatorGroup, operator: this.filterDistributorGroup, is_organization: this.filterIsOrganization},
          params: this.filterFormat()
        })
          .then(response => {
              if(response.data.resp_status == "OK"){
                this.finance = response.data.resp_result.result
                this.total = response.data.resp_result.total
                this.paginations = response.data.resp_result.paginations
              }else if(response.data.resp_errmsg){
                this.finance = []
                this.paginations = {}
                this.$router.app.$sheet.show("Помилка!", response.data.resp_errmsg);
              }else {
                this.finance = []
                this.paginations = {}
              }
          })
          .catch(err => { this.$router.app.$sheet.show("Помилка Достуту в біллінг!", err) })
          .finally(() => (this.loading = false))
			},
      filterFormat() {
        this.countFilters = 0
        const temp = {};
        temp.itemsPerPage = this.options.itemsPerPage;
        temp.page = this.options.page;
        return temp; 
      },
      changeDistributorList(){
        this.filterDistributorGroup = null
        if(!this.filterOperatorGroup) {
          return
        }
        let distributor = this.operators_groups.find(x => x.id == this.filterOperatorGroup)?.distributor
        this.distributorList = distributor.filter(x => x.inet)
        this.filterDistributorGroup = this.distributorList[0] && this.distributorList[0].id || null
      }
      
    
    },
  }
</script>

<style>
.table-finance-light-custom td.custom-table {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.table-finance-dark-custom td.custom-table  {
  border-right: thin solid rgba(238, 238, 238, 0.12);
}
.table-finance-light-custom td.text-color-custom{
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
}
.table-finance-dark-custom td.text-color-custom{
  color: rgba(238, 238, 238, 0.7);
  font-weight: bold;
}
td.fix-size-column:not(.v-data-table__mobile-row) {
  width: 120px
}

.link_text{
  text-decoration: none;
  cursor: pointer;
  color: #1565C0
}


</style>