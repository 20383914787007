<template>
  
  <v-container fluid>
    <v-row class="mt-3 mb-2" justify="center">
        <v-col
            cols="6"
            md="3"
          >
          <v-text-field
            v-model="date_time"
            dense
            outlined
            hide-details
            label="Дата та Час"
            type="datetime-local"
          ></v-text-field>
        </v-col>
        <v-col
            cols="6"
            md="3"
          >
          <v-text-field
            v-model="ip_address"
            dense
            outlined
            hide-details
            label="IP адреса"
          ></v-text-field>
        </v-col>
        
        <v-col
          cols="12"
          md="2"
          lg="1"
          class="text-center"
        >
          <v-btn color="primary" @click="getHistoryIp()"
          >
            Отримати
          </v-btn>
        </v-col>
      </v-row>
    <v-row justify="center" class="">
      <v-col  md="12" lg="12" xl="12">
        <v-data-table
          :headers="headers"
          loading-text="Завантаження..."
          no-data-text="Нема данних для відображення"
          hide-default-footer
          :items-per-page="-1"
          :items="history"
          :options.sync="options"
          :footer-props="footer"
          :loading="loading"
          class="elevation-1 pa-3"
          focusable
        >
          <template v-slot:top>

            <v-toolbar dense flat :color="$vuetify.theme.dark ? 'background2' : 'white'">
              <v-toolbar-title ><h2 class="mr-6">Історія IP</h2></v-toolbar-title>
            
              <v-spacer />
              <h2 >{{ ip_address }}</h2>
              <v-spacer />
              
              
            </v-toolbar>
            
          </template>

        <!-- ---------------------------------------- Cols ----------------------------------------- -->

        
        
        <template #item.address="{ item }">
            <div href="#"  class="link_text" :class="$vuetify.theme.dark ? 'primary--text text--lighten-1' : ''" @click.prevent="openAbonentInfoModal(item)">{{ item && item.address  || '___' }} <v-icon title="Видалений абонент" color="error" style="vertical-align: baseline;" small class="ml-1" v-if="item && item.is_removed">mdi-alert-circle</v-icon></div>
          </template>
        <template #item.traffic="{ item }">

              <v-chip v-if="item.traffic" small outlined :color="convertBytes(item.traffic)[1]">{{ convertBytes(item.traffic)[0] }}</v-chip>
        </template>
        
        <!-- ---------------------------------------- /Cols ----------------------------------------- -->
        
        </v-data-table>
      </v-col>
      </v-row>
      <keep-alive>
        <AbonentInfoModal ref="AbonentInfoModal" backTo="Звіти" />
      </keep-alive>
  </v-container>
</template>

<script>
  import axios from "axios"
  import { mapGetters } from "vuex"
  import AbonentInfoModal from '@/components/Job/AbonentInfoModal.vue'
  export default {
    name: "report-history-ip",
    props: ["user"],
    components: {
      AbonentInfoModal
    },
    data: () => ({
      date_time: null,
      ip_address: '',
      updateTable: 0,
      history: [],
      form_data: {},
      loading: false,
      options: {},
      headers: [
        { text: 'Вхід', value: 'datein', },
        { text: 'Вихід', value: 'dateout', },
        { text: 'Абонент', value: 'address', sortable: false,  },
        { text: 'Трафік', value: 'traffic', },
        //{ text: 'Отримана IP', value: 'ip', },
        { text: 'Caller ID', value: 'caller', },
        { text: 'Пристрій', value: 'hostname', },
      ],
      footer: {
        "items-per-page-options": [10,25,50],
        "items-per-page-text": " ", 
        "items-per-page-all-text": "Всі", 
        "show-first-last-page": true,
        "show-current-page": true,
      },
    }),
    computed: {
        ...mapGetters({
          //
        }),
    },
    mounted(){
      //
    },
    activated(){
      //
    },
    watch: {
      options: {
        handler (val, oldVal) {
          oldVal.page && this.getHistoryIp();
        },
        deep: true,
      },
      updateTable(){
        this.updateTable && this.getHistoryIp();
      },
    },
    methods: {
      getHistoryIp(){
        this.loading = true
            
            axios({
                method: "post",
                url: this.$router.app.devUrlBillingAPI,
                data: {req_cmd: "findUsers", ip: this.ip_address, date_time: this.date_time},
            })
                .then(response => {
                    if(response.data.resp_status == "OK"){
                      this.history = response.data.resp_result
                    }else if(response.data.resp_errmsg){
                      this.history = []
                      this.$router.app.$sheet.show("Помилка!", response.data.resp_errmsg);
                    }
                    else{
                      this.history = []
                    }
                    //console.log(response)
                })
                .catch(err => {
                    //console.log(err);
                    this.$router.app.$sheet.show("Помилка Достуту в біллінг!", err);
                })
                .finally(() => (this.loading = false))
      },
      convertBytes(bytes){
        if(!bytes) return ['0 bytes', 'grey']
        if(bytes > 10**12) return [(bytes * 10**-12).toFixed(1) + ' Тб', 'orange']
        else if(bytes > 10**9) return [(bytes * 10**-9).toFixed(2) + ' Гб', 'blue']
        else if(bytes > 10**6) return [(bytes * 10**-6).toFixed(2) + ' Мб', 'green']
        else  return [(bytes * 10**-6).toFixed(3) + ' Мб', 'deep-orange']
      },
      async openAbonentInfoModal(abonent){
        this.$refs.AbonentInfoModal.paramsUrlId = abonent.user
        this.$refs.AbonentInfoModal.dialog = true
      },
    },
  }
</script>

<style scoped>
.text-ex-space{
  white-space: nowrap !important /* Запрещаем перенос слов */
}
.link_text{
  text-decoration: none;
  cursor: pointer;
  color: #1565C0
}
</style>